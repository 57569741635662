<template>
  <CCol class="col-12">
    <h5>Project Owner</h5>
  </CCol>
  <CCol>
    <CNInput
      v-model="form.owner_name"
      label="Name"
      :disabled="disabled"
      :error="validationErrors.owner_name"
      :invalid="!!validationErrors.owner_name"
      @blur="validateField('owner_name')"
    />
  </CCol>

  <CCol class="mt-3 mt-sm-0 mt-md-0 mt-lg-3 mt-xl-0">
    <div class="inline-block w-100 text-end">
      <span class="pointer btnStageEdit" @click="handleEditOwner">
        <CIcon class="icon" name="cilPen" />
        <span class="additionBtn"> Edit </span>
      </span>
    </div>
    <CNSelect
      v-model="form.owner_type_id"
      :options="ownerTypes"
      :disabled="disabled"
      :error="validationErrors.owner_type_id"
      :invalid="!!validationErrors.owner_type_id"
      label="Owner Type"
      @change="validateField('owner_type_id')"
    />
  </CCol>

  <CCol v-if="editOwner" class="col-12">
    <Owners
      :owners="ownerTypes"
      @updated="fetchOwnerTypes"
      @close="editOwner = false"
    />
  </CCol>

  <CCol class="col-12 text-end">
    <span class="editManually pointer" @click="isManually = !isManually">
      {{ title }}
    </span>
  </CCol>
  <CCol v-if="isManually" class="col-12">
    <GoogleSearch
      :address="form.full_address"
      :disabled="disabled"
      @update="handleChangeAddress"
    />
  </CCol>
  <CCol v-if="!isManually" class="col-12">
    <CNInput
      v-model="form.owner_address"
      :disabled="disabled"
      label="Street Address"
      :error="validationErrors.owner_address"
      :invalid="!!validationErrors.owner_address"
      @blur="validateField('owner_address')"
    />
  </CCol>

  <CCol v-if="!isManually" class="col-12">
    <CRow :xs="{ gutterY: 3 }">
      <CCol class="col-12 col-xl-5">
        <CNInput
          v-model="form.city"
          :disabled="disabled"
          label="City"
          :error="validationErrors.city"
          :invalid="!!validationErrors.city"
          @blur="validateField('city')"
        />
      </CCol>
      <CCol class="col-12 col-xl-4">
        <CNInput
          v-model="form.owner_zip"
          :disabled="disabled"
          label="Zip Code"
          :mask="CONF.MASK_FORMAT_ZIP"
          :error="validationErrors.owner_zip"
          :invalid="!!validationErrors.owner_zip"
          @blur="validateField('owner_zip')"
        />
      </CCol>
      <CCol class="col-12 col-xl-3">
        <CNSelect
          v-model="form.state_id"
          :disabled="disabled"
          :options="states"
          :error="validationErrors.state_id"
          :invalid="!!validationErrors.state_id"
          label="State"
          @change="handleChangeState"
        />
      </CCol>
    </CRow>
  </CCol>

  <CCol class="col-12">
    <CRow :xs="{ gutterY: 3 }">
      <CCol class="col-12 col-xl-5 col-xxl-5">
        <CNInput
          v-model="form.poc_phone"
          label="Phone #"
          :disabled="disabled"
          :mask="CONF.MASK_FORMAT_PHONE"
          :error="validationErrors.poc_phone"
          :invalid="!!validationErrors.poc_phone"
          @blur="validateField('poc_phone')"
        />
      </CCol>
      <CCol class="col-12 col-xl-4 col-xxl-5">
        <CNInput
          v-model="form.owner_fax"
          :disabled="disabled"
          label="Fax #"
          :mask="CONF.MASK_FORMAT_FAX"
          :error="validationErrors.owner_fax"
          :invalid="!!validationErrors.owner_fax"
          @blur="validateField('owner_fax')"
        />
      </CCol>
      <CCol class="col-12 col-xl-3 col-xxl-2">
        <CNInput
          v-model="form.timezone"
          label="Time Zone"
          disabled
          :error="validationErrors.timezone"
          :invalid="!!validationErrors.timezone"
          @blur="validateField('timezone')"
        />
      </CCol>
    </CRow>
  </CCol>

  <CCol>
    <CNInput
      v-model="form.poc_email"
      :disabled="disabled"
      label="POC Email"
      :error="validationErrors.poc_email"
      :invalid="!!validationErrors.poc_email"
      @blur="validateField('poc_email')"
    />
  </CCol>

  <CCol>
    <CNInput
      v-model="form.poc_name"
      label="POC First & Last Name"
      :disabled="disabled"
      :error="validationErrors.poc_name"
      :invalid="!!validationErrors.poc_name"
      @blur="validateField('poc_name')"
    />
  </CCol>
</template>

<script>
import errors from '@/mixin/errors'
import reloadData from '@/mixin/reloadData'
import { mapActions, mapGetters } from 'vuex'
import modalComponent from '@/mixin/modalComponent'
import CONF from '@/utils/constants/config'
import Owners from './Owners'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import GoogleSearch from './GoogleSearch'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  name: 'OwnerBlock',
  components: {
    Owners,
    GoogleSearch,
  },
  mixins: [errors, reloadData, modalComponent],
  inject: ['toast'],
  props: {
    form: { type: Object, default: () => {} },
    states: { type: Array, default: () => [] },
  },
  emits: ['fetchData'],
  data: () => ({
    CONF,
    isManually: false,
    editOwner: false,
    validator: {},
    validationErrors: {},
    ownerTypes: [],
  }),
  computed: {
    ...mapGetters({
      permissions: 'projectPermissions',
    }),
    disabled() {
      return !this.permissions.editOrCreate
    },
    title() {
      return this.isManually ? 'Enter Manually' : 'Go to Google API'
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchOwnerTypes()
      this.setRules()
    })
  },
  methods: {
    ...mapActions([
      'fetchCountries',
      'getStates',
      'getCities',
      'createProject',
      'updateProject',
    ]),
    handleChangeAddress(data) {
      this.form.state_id = data.state_id
      this.form.owner_zip = data.post_code
      this.form.owner_address = data.street
      this.form.city = data.city
      this.form.timezone = data.timezone
      this.form.full_address = data.full_address
      this.form.county = data.county
    },
    handleEditOwner() {
      !this.permissions.editOrCreate
        ? this.$notify(permissionError)
        : (this.editOwner = !this.editOwner)
    },
    validateAll() {
      const validationResult = this.validator.validateAll(this.form)
      this.validationErrors = this.$deepClone(validationResult.validationErrors)
      return !validationResult.hasErrors
    },
    setRules() {
      this.validator = validator({
        owner_name: [() => (this.form.owner_name ? 0 : -1), rules.strMax(255)],
        poc_name: [() => (this.form.poc_name ? 0 : -1), rules.strMax(255)],
        poc_email: [
          () => (this.form.poc_email ? 0 : -1),
          rules.strMax(255),
          rules.email,
        ],
        city: [rules.nullable, rules.strMax(255)],
        owner_address: [rules.nullable, rules.strMax(255)],
      })
    },
    fetchOwnerTypes() {
      this.$http.projects
        .getOwnerTypeList()
        .then(({ data }) => {
          this.ownerTypes = data.data.map((el) => ({
            name: el.name,
            value: el.id,
          }))
          if (
            this.ownerTypes.find(
              ({ value }) => value === this.form.owner_type_id,
            ) === undefined
          )
            this.form.owner_type_id = null
        })
        .catch((errors) => console.log(errors))
    },
    validateField(f) {
      this.validationErrors[f] = this.validator.validate(f, this.form[f])
    },
    handleChangeState(val) {
      this.form.state_id = val
      let state = this.states.find((el) => el.value === val)
      if (state) {
        this.form.timezone = state.timezone
      }
      this.validateField('state_id')
    },
  },
}
</script>

<style scoped>
.editManually {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #1c262f;
}
</style>
