import Navigation from '@/components/Card/Navigation'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import CDivider from '@/components/CDivider'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import Stages from '../components/Stages'
import errors from '@/mixin/errors'
import reloadData from '@/mixin/reloadData'
import { mapGetters, mapActions } from 'vuex'
import modalComponent from '@/mixin/modalComponent'
import OwnerBlock from '../components/OwnerBlock'
import GeneralInformationBlock from '../components/GeneralInformationBlock'
import ProjectLocationBlock from '../components/ProjectLocationBlock'
import AddCustomField from '../components/CustomField/AddCustomField'
import CustomFieldBlock from '../components/CustomField/CustomFieldBlock'
import { isEqual } from 'lodash/lang'
import BuilderSteps from '@/views/Projects/Project/Components/BuilderSteps'
import FixedBottomPanel from '@/views/Projects/Project/Components/FixedBottomPanel'

export default {
  mixins: [errors, reloadData, modalComponent],
  inject: ['toast'],
  components: {
    Navigation,
    ConfirmDialog,
    CDivider,
    Stages,
    OwnerBlock,
    GeneralInformationBlock,
    ProjectLocationBlock,
    AddCustomField,
    CustomFieldBlock,
    BuilderSteps,
    FixedBottomPanel,
  },
  props: {
    project: { type: Object, default: () => {} },
  },
  emits: ['fetchData', 'update'],
  data() {
    return {
      childRef: [
        'generalInformationBlock',
        'ownerBlock',
        'projectLocationBlock',
        'generalInformationCustom',
        'ownerCustom',
        'projectLocationCustom',
      ],

      stagesInEdit: false,
      saveDirty: false,

      loading: false,
      sectionsList: [],
      sectionsCustomFields: [],
      companies: [],
      stages: [],
      states: [],
      key: 0,
      validationErrors: {},
      form: {},
      origin: {},
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchStage()
      this.fetchOffices()
      this.fetchStates()
      this.fetchSectionsCustomFields()
      this.setRules()
      this.initForm()
    })
  },
  computed: {
    ...mapGetters({
      permissions: 'projectPermissions',
      countries: 'countries',
      offices: 'offices',
    }),
    disabled() {
      return !this.permissions.editOrCreate
    },
    generalSectionId() {
      return this.getIdSection('General Information')
    },
    locationSectionId() {
      return this.getIdSection('Project Location')
    },
    ownerSectionId() {
      return this.getIdSection('Owner Information')
    },
    disableNextBtn() {
      return this.form
        ? !(
            this.form.contract_approval_workflow_id &&
            this.form.scope_approval_workflow_id
          )
        : true
    },
    links() {
      return [
        { name: 'Project Details', route: '' },
        {
          name: 'Team & Approvals',
          disabled: !this.$route.params.id,
          route: {
            name: 'ProjectTeams',
            params: { id: this.$route.params.id },
          },
        },
        {
          name: 'Manage Collections',
          disabled: !this.project?.template_collection_id,
          route: {
            name: 'ProjectTemplateCollection',
            params: { id: this.$route.params.id },
          },
        },
      ]
    },
    isEditForm() {
      return !isEqual(this.form, this.origin)
    },
    fixedBottomPanelButtons() {
      return [
        {
          text: 'Cancel',
          disabled: this.loading || !this.isEditForm,
          outline: true,
          event: () => this.handleCancel(),
        },
        {
          text: 'Save',
          // disabled: !this.existingUsers.length,
          outline: true,
          event: () => this.handleSave(false),
        },
        {
          text: 'Save & next',
          // disabled: !this.existingUsers.length,
          event: () => this.handleSave(true),
        },
      ]
    },
  },
  methods: {
    ...mapActions(['fetchOffices']),
    onStagesEditToggle(status) {
      this.stagesInEdit = status;

      if (!status) this.saveDirty = false;
    },
    async handleCancel() {
      if (this.isEditForm) {
        await this.$refs.confirmDialog
          .confirm({
            text: this.$t('messages.unsaved'),
            cancelText: 'Cancel',
            confirmText: 'OK',
          })
          .then((res) => {
            if (res) this.cancel()
          })
      } else {
        this.cancel()
      }
    },
    cancel() {
      this.form = this.$deepClone(this.origin)
      window.scrollTo(0, 0)
      this.validationErrors = {}
      this.key++
    },
    async deleteProject() {
      await this.$refs.confirmDialog
        .confirm({
          text: `Are you sure you want to delete project "${this.origin.name}", delete all related scopes and revoke all related tasks and contract assignments?`,
          cancelText: 'Cancel',
          confirmText: 'Yes, delete',
          reverse: true,
        })
        .then((res) => {
          if (res) {
            this.loading = true
            this.$http.projects
              .deleteProject(this.$route.params.id)
              .then((res) => {
                this.toast('success', res.data.message)
                this.$router.push({ name: 'ProjectsList' })
              })
              .catch(({ response }) =>
                this.toast('success', response.data.message),
              )
              .finally(() => (this.loading = false))
          }
        })
    },
    validateRefs() {
      let res = []
      this.childRef.forEach((el) => {
        res.push(this.$refs[el].validateAll())
      })

      return res.every((el) => el === true)
    },
    handleSave(isNext = false) {
      if (this.stagesInEdit) {
        this.saveDirty = true;
        return;
      };

      let childRefValid = this.validateRefs()
      const validationResult = this.validator.validateAll(this.form)
      if (!validationResult.hasErrors && childRefValid) {
        this.$route.params.id
          ? this.updateProject(isNext)
          : this.createProject(isNext)
      } else {
        this.validationErrors = this.$deepClone(
          validationResult.validationErrors,
        )
        this.validator.scrollToErrors()
      }
    },
    setErrorsToRef(errors) {
      let newErr = {}
      let checkLocation = false
      for (const [key, value] of Object.entries(errors)) {
        if (key.includes('location.') && !checkLocation) checkLocation = true

        let n = key.replace('location.', '').replace('owner.', '')
        newErr[n] = value
      }
      this.childRef.forEach((el) => {
        this.$refs[el].validationErrors = this.$deepClone(newErr)
      })

      if (checkLocation) this.$refs.projectLocationBlock.showGoogleSearch()
    },
    async createProject(isNext) {
      this.loading = true
      await this.$http.projects
        .createProject(this.mapForm())
        .then((res) => {
          this.origin = this.form
          let routeName = isNext ? 'ProjectTeams' : 'ProjectDetails'
          this.$router.push({
            name: routeName,
            params: { id: res.data.data.id },
          })
          this.toast('success', res.data.message)
        })
        .catch(({ response }) => {
          this.validationErrors = response.data.errors
            ? this.getErrors(response.data.errors)
            : {}
          this.setErrorsToRef(this.validationErrors)
          setTimeout(() => this.validator.scrollToErrors(), 100)
        })
        .finally(() => (this.loading = false))
    },
    updateProject(isNext) {
      this.loading = true
      this.$http.projects
        .updateProject(this.$route.params.id, this.mapForm())
        .then((res) => {
          if (isNext) {
            this.origin = this.$deepClone(this.form)
            this.$router.push({
              name: 'ProjectTeams',
              params: { id: res.data?.data?.id },
            })
          } else this.$emit('update')
          this.toast('success', res.data.message)
        })
        .catch(({ response }) => {
          this.validationErrors = response.data.errors
            ? this.getErrors(response.data.errors)
            : {}

          this.setErrorsToRef(this.validationErrors)
          setTimeout(() => this.validator.scrollToErrors(), 100)
        })
        .finally(() => (this.loading = false))
    },
    initForm() {
      this.form = this.$deepClone(this.project)
      if (!this.form.custom_fields) this.form.custom_fields = []
      this.origin = this.$deepClone(this.form)
    },
    mapForm() {
      let form = this.$deepClone(this.form)
      if (form.subsidiary_company_id === this.$store.getters.user.company.id) {
        delete form.subsidiary_company_id
      }
      form.estimated_start_date = form.estimated_start_date || undefined
      form.estimated_end_date = form.estimated_end_date || undefined

      return form
    },
    fetchStates() {
      this.$http.app
        .getStates({ params: { country_id: 1 } })
        .then(({ data }) => {
          this.states = data.data.map((el) => ({
            value: el.state_id,
            name: el.state_name,
            timezone: el.timezone
          }))
        })
    },
    getIdSection(name) {
      let obj = this.sectionsCustomFields.find((el) => el.name === name)
      return obj?.id
    },
    async deleteCustomField(id) {
      delete this.form.custom_fields[id]
      await this.fetchSectionsCustomFields()
    },
    async fetchSectionsCustomFields(force = false) {
      if (force) {
        await this.$emit('update')
      }

      await this.$http.projects
        .getProjectFields({ params: { project_id: this.form.id } })
        .then(({ data }) => {
          data.data.forEach((section) => {
            section.custom_fields.forEach((field) => {
              if (Array.isArray(field.options)) {
                let arr = []
                field.options.forEach((option) => {
                  arr.push({value: option, origin: option})
                })
                field.opt = arr
              }
            })
          })

          this.sectionsCustomFields = data.data

          data.data.forEach((item) => {
            item.custom_fields.forEach((el) => {
              if (!this.form.custom_fields[el.id])
                this.form.custom_fields[el.id] = {
                  field_id: el.id,
                  values: null,
                }
            })
          })
        })
        .then(() => (this.origin = this.$deepClone(this.form)))
    },
    fetchStage() {
      this.$http.projects
        .getStageList()
        .then(({ data }) => {
          this.stages = data.data.map((el) => ({ name: el.name, value: el.id }))
          if (
            this.stages.find(({ value }) => value === this.form.stage_id) ===
            undefined
          )
            this.form.stage_id = null
        })
        .catch((errors) => console.log(errors))
    },
    setRules() {
      this.validator = validator({
        awarded_date: [rules.required],
        stage_id: [rules.required, rules.number],
      })
    },
    validateField(f) {
      this.validationErrors[f] = this.validator.validate(f, this.form[f])
    },
  },
}
